import { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';
import { toast } from 'utils/toast';

export interface MyObject {
  getAuthCode: (options: {
    scopes: string[];
    success: (res: { authCode: string }) => void;
    fail: (err: any) => void;
  }) => void;
}

type AuthCode = string | null;

const my: MyObject = (window as any).my;
const userToken: any = window.qi.user?.mobile.token;

// Create a context to hold the authcode state
const AuthCodeContext = createContext<AuthCode>(null);

// Create a provider component to wrap your application
export const AuthCodeProvider = ({ children }: { children: ReactNode }) => {
  const [authcode, setAuthCode] = useState<AuthCode>(null);

  // Function to copy auth code
  const getAuthCode = () => {
    my.getAuthCode({
      scopes: ['USER_CONTACTINFO', 'auth_user'],
      success: async (res: { authCode: string }) => {
        const authCode = res.authCode;
        setAuthCode(authCode);
      },
      fail: (error: string) => {
        !userToken &&
          toast({
            status: 'error',
            description: `Error getting authCode! ${error}`,
          });
      },
    });
  };

  useEffect(() => {
    getAuthCode();
  }, []);

  return <AuthCodeContext.Provider value={authcode}>{children}</AuthCodeContext.Provider>;
};

// Custom hook to access authcode anywhere in the app
export const useAuthCode = (): AuthCode => useContext(AuthCodeContext);
