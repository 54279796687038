import { Box, Flex, Grid, Heading, Spinner } from '@chakra-ui/react';
import { ConcertCard } from 'components/ConcertCard/ConcertCard';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpcomingConcertsQuery } from './hooks';
//import announcemnt from '../../../assets/announcement.png';

export const Upcoming = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useUpcomingConcertsQuery();

  const handleCardClick = (concertId: number, eventId: number) => {
    navigate('/concert/' + concertId + '/' + eventId);
  };
  const numColumns = data && data.items.length >= 3 ? 3 : data && data.items.length;

  return (
    <Box
      as='section'
      bg='BG100'
      py='36px'
      color='dark.D900'
      textAlign='right'
      aria-labelledby='headingUpcoming'
    >
      <Box>
        <Heading
          textAlign={'center'}
          id='headingUpcoming'
          mb='24px'
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
        >
          <FormattedMessage defaultMessage='Upcoming Concerts' id='concerts.upcoming.heading' />
        </Heading>
        {isLoading ? (
          <Flex align='center' justify='center' mt='50px' mb='50px'>
            <Spinner color='green.G50' size='xl' thickness='4px' />
          </Flex>
        ) : data?.items.length ? (
          <Box width={'100vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid
              templateColumns={{
                base: `repeat(${1}, 1fr)`, // Adjusted to render appropriate number of columns
                md: `repeat(${1}, 1fr)`,
                lg: `repeat(${numColumns}, 1fr)`,
                xl: `repeat(${numColumns}, 1fr)`,
              }}
              justifyContent={'center'}
              style={{ direction: 'rtl' }}
              as='ul'
              listStyleType='none'
            >
              {data.items.map((concert) => (
                <div>
                  <ConcertCard
                    {...concert}
                    key={concert.id}
                    onCardClick={() => handleCardClick(concert.id, concert.eventId)}
                  />
                </div>
              ))}
            </Grid>
          </Box>
        ) : (
          <FormattedMessage defaultMessage='No upcoming Events' id='concerts.upcoming.noconcerts' />
        )}
      </Box>
    </Box>
  );
};
