import { sendRequest } from 'betterStackConsole';
import { useEffect, useState } from 'react';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { apiQiToken } from '..';
import { THIRTY_DAYS_IN_SECONDS } from 'utils/constants';
import { toast } from 'utils/toast';
import { apiAlipayAuthcode } from '../AliPayAuth/AliPayAuth';
import { captureException } from '@sentry/react';

export interface MyObject {
  getAuthCode: (options: {
    scopes: string[];
    success: (res: { authCode: string }) => void;
    fail: (err: any) => void;
  }) => void;
}
interface sucessProps {
  authCode: string;
}
const my: MyObject = (window as any).my;

export const useSignInOnce = () => {
  const signIn = useSignIn();
  const [signInInitiated, setSignInInitiated] = useState(false);
  const [authcode, setAuthCode] = useState<string | null>(null);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated() && !signInInitiated) {
      const userToken: any = window.qi.user?.mobile.token;
      if (userToken && !authcode) {
        try {
          // Sending errors to web console
          sendRequest(`user token ${userToken}`);

          // Fetching API for accessToken
          apiQiToken({ token: userToken })
            .then((data) => {
              sendRequest(data.accessToken);
              signIn({
                token: data.accessToken,
                expiresIn: data.expiresIn,
                tokenType: data.type,
                refreshToken: data.refreshToken,
                refreshTokenExpireIn: THIRTY_DAYS_IN_SECONDS,
              });
              toast({
                status: 'success',
                description: `Successfully signed in!`,
              });
            })
            .catch((error) => {
              // Handle API call errors
              sendRequest(error);
              console.error('Error fetching API token:', error);
              {
                userToken &&
                  toast({
                    status: 'error',
                    description: 'Failed to sign in. Please try again later.',
                  });
              }
            });
        } catch (error) {
          // Handle other errors
          console.error('Error occurred:', error);
          sendRequest(error);
          {
            userToken &&
              toast({
                status: 'error',
                description: 'Failed to sign in. Please try again later.',
              });
          }
        }
      } else {
        try {
          my.getAuthCode({
            scopes: ['USER_CONTACTINFO', 'auth_user'],
            success: async (res: sucessProps) => {
              const authCode = res.authCode;
              setAuthCode(authCode);

              try {
                await apiAlipayAuthcode({ auth_code: authCode }).then((res) => {
                  const accessToken: string = res.accessToken;
                  if (accessToken) {
                    signIn({
                      token: res.accessToken,
                      expiresIn: res.expiresIn,
                      tokenType: res.type,
                      refreshToken: res.refreshToken,
                      refreshTokenExpireIn: res.expiresIn,
                      authState: { phone: '', session: '', name: '', email: '', gender: '' },
                    });
                  } else {
                    authcode &&
                      toast({
                        status: 'error',
                        description: 'لم يتم العثور على رمز الوصول في الاستجابة.',
                      });
                    captureException('Access token not found in the response.');
                  }
                });
              } catch (error) {
                authcode &&
                  toast({
                    status: 'error',
                    description: `حدث خطأ أثناء تسجيل الدخول! ${error}`,
                  });
                captureException(error);
              }
            },
            fail: (error) => {
              authcode &&
                !userToken &&
                toast({
                  status: 'error',
                  description: `حدث خطأ أثناء تسجيل الدخول! ${error}`,
                });
              captureException(error);
            },
          });
        } catch (error) {
          toast({
            status: 'error',
            description: `Error in superApp! ${error}`,
          });
          captureException(error);
        }
      }
      setSignInInitiated(true);
    }
  }, [window.qi.user?.mobile.token, authcode]);

  return null; // Since this hook doesn't render any JSX
};
